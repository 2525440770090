import React, { Component } from "react";
import Compose from "../Compose";
import Toolbar from "../Toolbar";
import Message from "../Message";
import moment from "moment";
import axios from "axios";

import "./MessageList.css";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import Api from "../../customlib/Api";
import Global from "../../customlib/Global";
import { Drawer, Snackbar, FormControl, InputLabel, Select, MenuItem, TextField, Autocomplete, FormControlLabel, Switch, Alert, Tooltip, Fab, IconButton } from "@mui/material";
import md5 from "md5";
import TemplateMessage from "../TemplateMessage";
import ReactLoading from "react-loading";
import { LockOpen, VolumeUp, VolumeOff, Check, CheckCircle, Circle, Close, Add, Pin, EditNotifications, SpeakerNotes, ArrowDownward, ArrowDropDownCircle, ArrowDropDown, AddShoppingCart, Archive } from "@mui/icons-material"
import WelcomeMessage from "../Messenger/WelcomeMessage";
import mp3File from "../../sound/notification.mp3";
import newContactMp3File from "../../sound/new_contact.mp3";
import csMp3File from "../../sound/cs_notification.mp3";
import slowAlarmMp3File from "../../sound/buzzer.mp3";
import unfinishedChatMp3File from "../../sound/unfinished-chat.mp3";
import pesanBelumDibalasMp3File from "../../sound/pesan-belum-dibalas.mp3";
import customerNonTeleBelumDiFuMp3File from "../../sound/customer-nontele-belum-difu.mp3";
import Modal from "react-bootstrap/Modal";
import LotusDropzone from "../LotusDropZone";
import Compressor from "compressorjs";
import base64 from "base-64";
import CreateOrder from "../CreateOrder";
import TopAlert from "./TopAlert";
import CustomerProfile from "../CustomerProfile";
import PinnedMessageBubble from "../ContactNotes/PinnedMessageBubble";
import NotifyFuSalesOnline from "../FollowUp123/NotifyFuSalesOnline";

// const MY_USER_ID = ["6281231828249", "447860099299"];

// var socket;
var DataTransfer = require('fbjs/lib/DataTransfer');
class MessageList extends Component {

  state = {
    messages: [],
    newMessage: "",
    open: false,
    files: [],
    fileType: "IMAGE",//or DOCUMENT
    origFileName: "",
    snackbarVisible: false,
    mustSendGreeting: false, 
    right: false,
    isLoading: false,
    audio: new Audio(mp3File),
    newContactAudio: new Audio(newContactMp3File),
    csAudio: new Audio(csMp3File),
    slowAlarmAudio: new Audio(slowAlarmMp3File),
    unfinishedChatAudio: new Audio(unfinishedChatMp3File),
    pesanBelumSibalasAudio: new Audio(pesanBelumDibalasMp3File),
    customerNonTeleBelumDiFuAudio: new Audio(customerNonTeleBelumDiFuMp3File),
    showSetLabelDialog: false,
    cancelCategory: "",
    keteranganPending: "",
    keteranganCancel: "",
    showCancelCategory: false,
    tipeLead: "",
    permasalahan: "",
    alasanUmum: "",
    alasanRinci: "",
    errorMessage: "", 
    tmpLabel: "",
    selectedCity: "",
    selectedCityId: 0,
    deliveryDate: "",
    defaultDeliveryDate: "",
    keterangan: "",
    emailRegister: "",
    listCity: [],
    muteSound: false,
    showLinkCreateEnquiries: false,
    isArchivedMessagesLoaded: false,
    showCreateCoConfirmationDialog: false,
    drawerMode: "",
    messageText: null,
    listPesanBelumDibalas: [],
    showDialogPinnedMessage: false,
    contactNotes: [],
    listFuSalesOnline: []
  };

  // playAudio = () => {
  //   const audioEl = document.getElementsByClassName("audio-element")[0]
  //   audioEl.play()
  // }

  DRAWER_MODE_HSM = "HSM";
  DRAWER_MODE_CREATE_ORDER = "CREATE_ORDER";
  DRAWER_MODE_CUSTOMER_PROFILE = "CUSTOMER_PROFILE";

  chatContainer = React.createRef();
  constructor(){
    super();
    Global.getSocket().on("newMessageComing", data => {
        console.log("NEW_MESSAGE_COMING_LOCAL", data, );
        console.log("NEW_MESSAGE_COMING_LOCAL",Global.getAppProfile().waNumber, data.to);
        let isNewContact = data.isNewContact ?? false;

        if(Global.get() === undefined || Global.get() === null){
          return;
        }

        if(Global.getAppProfile().waNumber !== data.to){
          console.log("DALAMIF",Global.getAppProfile().waNumber, data.to);
          return;
        }

        console.log("CURRENT_CONV_CHECK", this.props.currentConversation);
        if(this.props.currentConversation !== undefined && data.from === this.props.currentConversation.fromNumber){
          // console.log("NEW_MESSAGE_COMING", data);
            this.state.messages.push(data);
            

            let lastMessage = "";
            if(data.message.text !== undefined){
              lastMessage = data.message.text;
            }else{
              if(data.message.caption !== undefined){
                lastMessage = data.message.caption;
              }else if(data.message.type === "STICKER"){
                lastMessage = "Stiker";
              }
            }
            
            this.setState({ mustSendGreeting: false }, () => this.scrollToMyRef());

            this.props.onNewIncomingMessage(this.props.currentConversation, lastMessage);
            this.props.onResetUnreadCounter(this.props.currentConversation.contactId, false);
            this.props.onMarkAsRead(this.props.currentConversation);
        }else{
            this.props.onUpdateReadCounter(data);
        }

        var isMyContact = false;
        try{
          isMyContact = this.props.conversationList.filter((contact) => contact.fromNumber===data.from);
        }catch(err){

        }

        if(this.state.muteSound === true){
          return;
        }

        if(Global.get().admin === true){
          try{
            if(isNewContact){
              this.state.newContactAudio.play();
            }else{
              this.state.audio.play();
            }
            
          }catch(err){
            console.log("PLAYERROR", err);
          }
        // }else if(data.assignToUserId === Global.get().user.id){
        }else if(isMyContact.length > 0 || data.assignToUser === Global.get().user.id){
          try{
            if(isNewContact){
              this.state.newContactAudio.play();
            }else{
              this.state.audio.play();
            }
          }catch(err){
            console.log("PLAYERROR", err);
          }
        }

    });

    Global.getSocket().on("newContactAssigned", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      if(Global.isSalesDataPending() === true || Global.get().admin === true || Global.isAdminTeleUser() === true || Global.isAdminEpUser() === true){
        return;
      }

        console.log("NEW_CONTACT_ASSIGNED", data);

        let check = [];

        try{
          check = data.filter((newContact) => newContact.assignToUser === Global.get().user.id);
        }catch(err){

        }

        if(Global.get().admin === true){
          this.props.onUpdateConversations(false);
            console.log("NEW_CONTACT_FOUND");
          if(this.state.muteSound === false){
            try{
              this.state.newContactAudio.play();
            }catch(err){
              console.log("PLAYERROR", err);
            }
          }
          
        }else if(check.length > 0){
          // if(Global.get().user.id === data.assignToUser){
            this.props.onUpdateConversations(false);
            console.log("NEW_CONTACT_FOUND");
            if(this.state.muteSound === false){
              try{
                this.state.newContactAudio.play();
              }catch(err){
                console.log("PLAYERROR", err);
              }
            }
            
          // }
        }

    });

    Global.getSocket().on("forceReloadContact", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

        console.log("FORCE_RELOAD_CONTACT", data);
        // console.log("GLOBALVALUE", Global.get().user.id, " ", data.assignToUser);

        let check = [];

        try{
          check = data.filter((forceReload) => forceReload.userId===Global.get().user.id);
        }catch(err){

        }

        if(check.length > 0){
          // if(Global.get().user.id === data.userId){
            this.props.onUpdateConversations(false);
            console.log("FORCED_RELOAD_CONTACT");
          // }
        }
        
    });

    Global.getSocket().on("userAutoleadsStateChanged", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

        console.log("USER_AUTOLEADS_STATE_CHANGED", data);
        // console.log("GLOBALVALUE", Global.get().user.id, " ", data.assignToUser);

        let check = false;

        try{
          // check = data.filter((forceReload) => forceReload.userId===Global.get().user.id);
          if(data.salesData.lavenderSalesId === Global.get().user.id && data.salesData.appProfileId === Global.getAppProfile()._id){
            check = true;
          }

          if(Global.get().admin || Global.isSuperUser()){
            check = true;
          }
        }catch(err){

        }

        if(check === true){
          // if(Global.get().user.id === data.userId){
            this.props.onUpdateUserAutoleadsState(data.salesData);
            console.log("USER_AUTOLEADS_STATE_CHANGED_UPDATE", data);
          // }
        }
        
    });

    Global.getSocket().on("messageDeliveryStatus", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

        // console.log("DELIVERY_STATUS", data);

        if(data === null){
          return;
        }

        try {
          if(data.to !== this.props.currentConversation.fromNumber){
            return;
          }  
        } catch (error) {
          
        }
        

        const clonedMessages = [...this.state.messages];

        let message = [];

        try{
          message = clonedMessages.filter((msg) => msg.reffId===data.reffId);
        }catch(err){

        }

        if(message.length > 0){
          message[0].messageId = data.messageId;
          message[0].status = data.status;

          if(message[0].message.type === "IMAGE"){
            console.log("FILENAME : ", data.message.fileName);
            message[0].message.fileName = data.message.fileName;
          }

        }

        // for (let index = 0; index < clonedMessages.length; index++) {
        //   const element = clonedMessages[index];
          
        //   if(element.messageId == data.messageId){
        //     element.status = data.status;

        //     if(element.message.type === "IMAGE"){
        //       element.message.fileName = data.message.fileName;
        //     }

        //     break;
        //   }

        // }

        this.setState({messages: clonedMessages});

    });

    Global.getSocket().on("helpRequestChanged", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      console.log("helpRequestChanged : ", data);
      
      if(Global.get().admin === true || Global.isCustomerServiceSpv() === true || Global.isCustomerService() === true){
        if(this.state.muteSound === false){
          try{
            this.state.csAudio.play();
          }catch(err){
            console.log("PLAYERROR", err);
          }
        }
      }

      this.props.onUpdateCsHelpState(data);
      
    });

    Global.getSocket().on("slowResponseMessage", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      console.log("slowResponseMessageSound : ", data);

      
      if(Global.get().admin === true){
        this.props.onUpdateSlow();
        if(this.state.muteSound === false){
          try{
            this.state.slowAlarmAudio.play();
          }catch(err){
            console.log("PLAYERROR", err);
          }
        }
      }

      // this.props.onUpdateCsHelpState(data);
      
    });

    Global.getSocket().on("justReadMessage", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      console.log("slowResponseMessageSound : ", data);

      
      if(Global.get().admin === true){
        this.props.onUpdateJustRead();
        if(this.state.muteSound === false){
          try{
            this.state.slowAlarmAudio.play();
          }catch(err){
            console.log("PLAYERROR", err);
          }
        }
      }
      
    });

    Global.getSocket().on("unfinishedChat", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      if(Global.isNeedUnfinishedChatReminder(Global.get().user.role) === false){
        return;
      }

      console.log("unfinishedChat : ", data);

      
      if(Global.get().admin === true && Global.getAppProfile().waNumber === data.to){
        this.props.onUpdateUnfinishedChat();
        if(this.state.muteSound === false){
          try{
            this.state.unfinishedChatAudio.play();
          }catch(err){
            console.log("PLAYERROR", err);
          }
        }
      }else{
        if(Global.get().user.id === data.assignToUser){
          this.props.onUpdateUnfinishedChat();
          if(this.state.muteSound === false){
            try{
              this.state.unfinishedChatAudio.play();
            }catch(err){
              console.log("PLAYERROR", err);
            }
          }
        }
      }

      // this.props.onUpdateCsHelpState(data);
      
    });

    Global.getSocket().on("forceLogout", data => {

      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      let params = data.params;
      // let message = data.message

      let shouldILogout = params.filter((element) => element === Global.get().user.id);

      if(shouldILogout.length > 0){
        console.log("forceLogout", shouldILogout);
        this.props.logoutHandler();
      }
      
    });

    Global.getSocket().on("inboundBelumDiReply", data => {

      console.log("inboundBelumDiReply", data);
      if(Global.get() === undefined || Global.get() === null){
        return;
      }

      let filteredNotif = [];

      if(Global.isCustomerService() || Global.isCustomerServiceSpv()){
        filteredNotif = data?.filter(item => item.isCsCustomer === true);
        console.log("inboundBelumDiReply","FILTERED", filteredNotif);
      }else if(Global.get().admin === true){
        // filteredNotif = data;
      }else{
        filteredNotif = data?.filter(item => item.userId === Global.get().user.id);
      }

      if(filteredNotif?.length > 0){
        this.setState({listPesanBelumDibalas: filteredNotif}, () => {
          try{
            this.state.pesanBelumSibalasAudio.play();
          }catch(err){
            console.log("PLAYERROR", err);
          }
        });
      }else{
        this.setState({listPesanBelumDibalas: []});
      }      

    });

    Global.getSocket().on("notifyFuSalesOnline", data => {

      console.log("notifyFuSalesOnline", data);
      if(Global.get() === undefined || Global.get() === null){
        return;
      }
      // return;
      let filteredNotif = [];

      if(Global.get().admin === true){
        filteredNotif = data?.data;
      }else{
        filteredNotif = data?.data?.filter(item => item.salesId === Global.get().user.id);
      }

      if(filteredNotif?.length > 0){
        this.setState({listFuSalesOnline: filteredNotif}, () => {
          try{
            this.state.customerNonTeleBelumDiFuAudio.play();
          }catch(err){
            console.log("PLAYERROR", err);
          }
        });
      }else{
        this.setState({listFuSalesOnline: []});
      }      

    });

  }
  getWaMessages = (isLoadArchived) => {

    if(this.props.currentConversation.fromNumber === ""){
      return;
    }
    
    this.setState({isLoading: true}, ()=>{
      const params = {
        from: this.props.currentConversation.fromNumber,
        useNest: false,
        loadArchived: isLoadArchived
      };
      console.log("PARAMSWAMESSAGE", params);
  
      axios
        .post(Api.messagesList(), params, Api.getRequestHeader())
        .then((response) => {
          console.log("YOUR_MESSAGES",response.data);
          // let data = response.data.data.map((result) => {
          //   return {
          //     id: 1,
          //     author: result.from,
          //     message: result.message.text,
          //     timestamp: result.receivedAt,
          //   };
          // });
          
          const responseData = response.data;

          let listMessages = responseData.data.filter((msg) => msg.from===this.props.currentConversation.fromNumber);
          let sendGreetingFirst = true;
          if(listMessages > 0){
            var now = moment(new Date()).tz("asia/jakarta"); //todays date
            var end = moment(listMessages[listMessages.length-1].receivedAt).tz("asia/jakarta"); // another date
            var oneDayInHours = 24; 
            
            var duration = moment.duration(now.diff(end));
            var delta = duration.asHours();
            console.log("CURRENTTIME ", now, " LAST MESSAGE TIME ", end, " DELTAINSECONDS ", delta, oneDayInHours);
            sendGreetingFirst = delta>24;
            
          }else{
            sendGreetingFirst = true;
            // this.setState({isLoading: false, mustSendGreeting: true});
          }

          if(isLoadArchived === true){

            let existingMessages = [...this.state.messages];
            let loadedArchivedMessages = response.data.data;

            loadedArchivedMessages = loadedArchivedMessages.concat(existingMessages);
            this.setState({ messages: loadedArchivedMessages, mustSendGreeting: false, isLoading: false, isArchivedMessagesLoaded: true }, () => this.scrollToMyRef());
          }else{
            this.setState({ messages: response.data.data, mustSendGreeting: false, isLoading: false, isArchivedMessagesLoaded: false }, () => this.scrollToMyRef());
          }          
          
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, isLoading: false });
          console.error("There was an error!", error);
        });
    });

    
  };

  //   handleSendMessage = () => {
  //     const { contactId, fromNumber, fromName } = this.props.currentConversation;

  //     const params = {
  //       contactId: contactId,
  //       fromNumber: fromNumber,
  //       fromName: fromName,
  //       message: this.state.newMessage,
  //     };

  //     axios
  //       .post(Api.sendMessage(), params, Api.getRequestHeader())
  //       .then((response) => {})
  //       .catch((error) => {});
  //   };

  scrollToMyRef = () => {
    const scroll =
      this.chatContainer.current.scrollHeight -
      this.chatContainer.current.clientHeight;
    this.chatContainer.current.scrollTo(0, scroll);
  };

  handleSendMessage = (message, conversation) => {

    // if(Global.isSalesDataPending() || Global.isSales()){
    //   if(conversation.salesOnlineFu === "FU1"){

    //   }else if(conversation.salesOnlineFu === "FU2"){

    //   }else if(conversation.salesOnlineFu === "FU3"){

    //   }else{

    //   }
    // }
    
    if(this.props.currentConversation.csHelpState === "SOLVING" && Global.isSales()){
      this.handleShowSnackbar(<>
        <span>Pelanggan ini sedang di take over oleh CS</span>
        <br />
        <span>Anda tidak dapat mengirim pesan kepada pelanggan ini hingga CS sudah selesai</span>
        </>
      );
      return;
    }

    if(this.state.mustSendGreeting){
      this.handleShowSnackbar(<>
        <span>Pesan terakhir sudah melebihi 24 jam </span>
        <br />
        <span>Silakan kirim pesan pembuka untuk memulai percakapan</span>
        </>
      );
    }else{
      if(this.state.newMessage.length > 0){
        let template = Global.getMessageTemplate();
        template.message.text = this.state.newMessage;
        template.to = this.props.currentConversation.fromNumber;
        template.contact.name = this.props.currentConversation.fromName;
        template.from = this.props.currentConversation.toNumber;
        template.receivedAt = Date.now();
        template.reffId = Date.now();

        if(this.state.files.length <= 0){
            this.sendTextMessage(template);
        }else{
            this.sendMultimediaMessage(template);
        }
      }
    }
  };

  checkBeforeSendmessage = () => {

    let now = moment(new Date()).tz("asia/jakarta");
    let currDate = moment(new Date()).tz("asia/jakarta").format("DD");
    let leadDate = moment(this.props.currentConversation.createdAt).tz("asia/jakarta").format("DD");

    let contact = this.props.currentConversation;
    let listMessages = this.state.messages.filter((msg) => msg.from === contact.fromNumber);
    let lastCustMessageDateTime = now;

    let lastCustMessage = null;
    // return true;
    console.log("CHECKDATE", currDate, leadDate);
    if(currDate !== leadDate){
      console.log("CHECKDATE_STEP", 1);
      return true;
    }

    if(contact.label !== "PENDING" && contact.label !== undefined && contact.label !== ""){
      console.log("CHECKDATE_STEP", 2);
      return true;
    }

    if(!Global.isSales() && !Global.isSalesDataPending()){
      console.log("CHECKDATE_STEP", 3);
      return true;
    }

    if(listMessages.length > 0){
      console.log("CHECKDATE_STEP", 4);
      lastCustMessage = listMessages[listMessages.length - 1];
      lastCustMessageDateTime = moment(lastCustMessage.created_at);
    }

    let duration = moment.duration(now.diff(lastCustMessageDateTime));
    let diffInMinutes = duration.asMinutes();

    console.log({
      now: now,
      lastCustMessageDateTime: lastCustMessageDateTime,
      lastCustMessage: lastCustMessage,
      delta: diffInMinutes,
      test: moment(lastCustMessage.created_at).tz("asia/jakarta").format("DD-MM-Y HH:mm:ss"),
      contact: contact
    });

    let lastFuSalesOnline = contact.salesOnlineFu;
    let messageContent = this.state.newMessage;
    let messageLength = messageContent.length;

    let resultFu = "";
    let resultAllowSendmessage = true;
    let remainingChars = 0;

    if (!["FU1", "FU2", "FU3"].includes(lastFuSalesOnline)) { //going to FU1
      console.log("CHECKDATE_STEP", 5, lastFuSalesOnline);

        if (diffInMinutes >= 15 && messageLength < 100) {
          console.log("CHECKDATE_STEP", 6);
            resultAllowSendmessage = false;
            remainingChars = 100 - messageLength;
        }
        resultFu = "FU1";
    } else if (lastFuSalesOnline === "FU1") { //going to fu 2
      console.log("CHECKDATE_STEP", 7);

        if (diffInMinutes > 30 && messageLength < 150) {
          console.log("CHECKDATE_STEP", 8);
            resultAllowSendmessage = false;
            remainingChars = 150 - messageLength;
        }
        resultFu = "FU2";
    } else if (lastFuSalesOnline === "FU2") { //going to FU3
      console.log("CHECKDATE_STEP", 9);

        if (diffInMinutes > 60 && messageLength < 100) {
          console.log("CHECKDATE_STEP", 10);
            resultAllowSendmessage = false;
            remainingChars = 100 - messageLength;
        }
        resultFu = "FU3";
    } else if (lastFuSalesOnline === "FU3") { //end fu
      console.log("CHECKDATE_STEP", 11);

    }

    if(!resultAllowSendmessage){
      this.handleShowSnackbar(<>
        <Alert onClose={this.handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          <span>Kirim pesan belum berhasil</span>
          <br />
          <span>{"Tambah " + remainingChars + " karakter lagi agar dapat melakukan follow up " + resultFu}</span>
        </Alert>
        
        </>
      );
    }

    return resultAllowSendmessage;
  }

  sendTextMessage = (message) =>{

    let check = this.checkBeforeSendmessage();
    if(!check){
      return;
    }

    this.state.messages.push(message);
    console.log("NEWMESSAGE", message);
    console.log("CONTACT", this.props.currentConversation);
    // this.state.newMessage = "";
    // this.state.messages.push(response.data);
    this.setState({ newMessage: "" }, () => this.scrollToMyRef());

    let params = {
        params: {},
        message: message
    };
    console.log("STRINGIFY", JSON.stringify(message));
    axios
      .post(Api.sendMessage(), params, Api.getRequestHeader())
      .then((response) => {
        // this.state.messages.push(response.data.data);
        if(response.data.success === true){
          const clonedMessages = [...this.state.messages];
          let message = clonedMessages.filter((msg) => msg.reffId===response.data.data.reffId);

          if(message.length > 0){
            message[0].messageId = response.data.data.messageId;
            message[0].status = response.data.data.status;
          }

          let listBelumDireply = [...this.state.listPesanBelumDibalas];
          let filteredListBelumDireply = listBelumDireply.filter(belum => belum.from !== message[0].to);

          this.setState({messages: clonedMessages, listPesanBelumDibalas: filteredListBelumDireply});
          console.log("RESPONSESEND",response.data, "LISTBELUMREPLY", listBelumDireply, "FILTERED", filteredListBelumDireply);
          this.props.onUpdateConversations(false);
          // this.props.onResetUnreadCounter(this.props.currentConversation.id, false);
        }else{
          this.handleShowSnackbar(<>
            <Alert onClose={this.handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
              <span>Kirim pesan belum berhasil</span>
              <br />
              <span>{response.data.message}</span>
            </Alert>
            
            </>
        );
          this.setState({errorMessage: response.data.message});
        }

        
      })
      .catch((error) => {
        this.handleShowSnackbar(<>
          <Alert onClose={this.handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            <span>Kirim pesan belum berhasil</span>
            <br />
            <span>{error.message}</span>
          </Alert>
          
          </>
      );
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  sendMultimediaMessage = (message) => {

    let check = this.checkBeforeSendmessage();
    if(!check){
      return;
    }

    const splitFileName = this.state.origFileName.split(".");
    let origFileName = md5(Date.now() + this.state.origFileName) + "." + splitFileName[splitFileName.length-1];
    origFileName = origFileName.replace(".webp", ".png");
    // console.log("RENAMED : ", origFileName);
    // return;

    message.message.caption = this.state.newMessage;
    // message.message.type = this.state.files[0].type.indexOf("image") !== -1 ? "IMAGE" : "DOCUMENT";
    message.message.type = this.state.origFileName.toLowerCase().endsWith(".pdf") ? "DOCUMENT" : "IMAGE";
    // message.message.fileName = this.state.files[0].name;
    message.message.fileName = origFileName;

    // console.log("MULTIMEDIA MESSAGE");
    let params = {
        params: {},
        message: message,
        origFileName: origFileName
    };
    // console.log("STRINGIFY", JSON.stringify(message));
    console.log("MESSAGEORI", params);
    // this.state.messages.push(message);
    // this.setState({ newMessage: "", files: [], fileType: "IMAGE", origFileName: "" }, () => this.scrollToMyRef());

    const data = new FormData() 

    if(message.message.type === "IMAGE"){
      try {
        new Compressor(this.state.files[0], {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.        
            // setCompressedFile(res)
            console.log("COMPRESSED_IMAGE",compressedResult);
            
            data.append('file', compressedResult);
            data.append("jsonData", JSON.stringify(params));
            this.callbackSendMultimediaMessage(data);
          },
        });  
      } catch (error) {
        data.append('file', this.state.files[0]);
        data.append("jsonData", JSON.stringify(params));

        this.callbackSendMultimediaMessage(data);
      }      
      
    }else{
      data.append('file', this.state.files[0]);
      data.append("jsonData", JSON.stringify(params));

      this.callbackSendMultimediaMessage(data);
    }

    // data.append("jsonData", JSON.stringify(params));

    // axios
    //   .post(Api.sendMultimediaMessage(), data, Api.getRequestHeader())
    //   .then((response) => {
    //     console.log("NEWMESSAGE", message);
    //     console.log("RESPONSESEND_MEDIA",response.data);
    //     this.state.messages.push(response.data.data);

    //     this.setState({ newMessage: "" }, () => this.scrollToMyRef());
    //     this.props.onUpdateConversations(false);
    //     this.setState({files: [], fileType: "IMAGE", origFileName: ""});        
    //   })
    //   .catch((error) => {
    //     this.setState({ errorMessage: error.message });
    //     console.error("There was an error!", error);
    //   });
  }

  callbackSendMultimediaMessage = (formData) => {
    axios
      .post(Api.sendMultimediaMessage(), formData, Api.getRequestHeader())
      .then((response) => {
        console.log("NEWMESSAGE", formData.get("jsonData"));
        console.log("RESPONSESEND_MEDIA",response.data);
        this.state.messages.push(response.data.data);

        this.setState({ newMessage: "" }, () => this.scrollToMyRef());
        this.props.onUpdateConversations(false);
        this.setState({files: [], fileType: "IMAGE", origFileName: ""});        
      })
      .catch((error) => {
        this.handleShowSnackbar(<>
          <Alert onClose={this.handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            <span>Kirim pesan belum berhasil</span>
            <br />
            <span>{error.message}</span>
          </Alert>
          
          </>
      );
        this.setState({errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  handleResendMessage = (message) =>{
    let params = {
        id: message.id
    };
    console.log("STRINGIFY", JSON.stringify(message));
    axios
      .post(Api.resendMessage(), params, Api.getRequestHeader())
      .then((response) => {
        console.log(response.data);

        const clonedMessages = [...this.state.messages];
        let message = clonedMessages.filter((msg) => msg.id===response.data.data.id);

        if(message.length > 0){
          message[0].messageId = response.data.data.messageId;
          message[0].status = response.data.data.status;
        }
        this.setState({messages: clonedMessages});


      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  getCityList = (message) =>{
    let params = {
    };
    // console.log("CITYLIST", JSON.stringify(message));
    axios
      .post(Api.getCityList(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("CITYLIST",response.data);

        let cityList = response.data.data;
        cityList.push({
          "id": 0,
          "parent_id": 0,
          "left": 0,
          "right": 0,
          "depth": 0,
          "name": "Belum diketahui",
          "alternames": "[\"Belum diketahui\"]",
          "country": "ID",
          "level": "ADM2",
          "population": 1177042,
          "lat": "-7.193300",
          "long": "112.553000",
          "subadminarea_gmaps_api": "Belum diketahui"
        });
        this.setState({listCity: cityList});
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  handleCityOnChange = (newCity) => {
    let selected = this.state.listCity.filter((city) => city.name===newCity);

    if(selected.length > 0){
      this.setState({selectedCity: selected[0].name, selectedCityId: selected[0].id}, () => {
        console.log("SELECTED CITY", selected[0]);
      });
    }

    
  }

  handleOnChange = (event) => {
    this.setState({ newMessage: event.target.value });
  };

  handleClearMessageInput = () => {
    this.setState({ newMessage: "" });
  };

  handleRequestBantuanCS = () => {

    if(this.props.currentConversation.csHelpState === "SOLVING"){
      return;
    }

    let helpState = "OPEN";
    if(this.props.currentConversation.csHelpState === "OPEN"){
      helpState = "OFF";
    }

    if(this.props.currentConversation.csHelpState === "SOLVED"){
      helpState = "OFF";
    }

    let params = {
      userId: Global.get().userId,
      contactId: this.props.currentConversation.contactId,
      helpState: helpState,
      archived: false,
      csId: 0,
      csName: ""
    };
    
    axios
      .post(Api.setCsHelp(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("SETCSHELP : ", response.data);
        this.setState({}, () => {
          // this.props.onUpdateConversations(false);
        });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  handleSetContactLabel = (conversation, labelValue) => {

    var labelsUseCategory = [
      Global.labelPaid(),
      Global.labelUnpaid(),
      // Global.labelCancel(),
      Global.labelDp(),
      Global.labelPending()
    ];


    if(![Global.tipeLeadSulitClosing(), Global.tipeLeadSedang(), Global.tipeLeadMudahClosing()].includes(this.state.tipeLead)){
      if(!Global.isCustomerService() && !Global.isCustomerServiceSpv()){
        this.setState({errorMessage: "Tipe lead belum dipilih"});
        return;
      }
    }

    if(labelsUseCategory.includes(labelValue) && this.state.cancelCategory.length <= 0){
      this.setState({errorMessage: "Kategori produk belum dipilih"});
      return;
    }

    if(labelsUseCategory.includes(labelValue) && this.state.selectedCityId <= 0){
      this.setState({errorMessage: "Kota belum dipilih"});
      return;
    }

    // if(labelsUseCategory.includes(labelValue) && this.state.selectedCityId.length <= 0){
    //   this.setState({errorMessage: "Kota belum dipilih"});
    //   return;
    // }

    if(labelValue === Global.labelUnpaid()){
      if(this.state.deliveryDate === "" || this.state.deliveryDate === null || this.state.deliveryDate === undefined){
        this.setState({errorMessage: "Tanggal Pengiriman harus diisi"});
        return;
      }else{
        this.setState({errorMessage: ""});
      }
    }

    if(labelValue === Global.labelPending()){
      if(this.state.deliveryDate === "" || this.state.deliveryDate === null || this.state.deliveryDate === undefined){
        this.setState({errorMessage: "Tanggal Pengiriman harus diisi"});
        return;
      }
      
      // if(this.state.keteranganPending === "" || this.state.keteranganPending === undefined){
      //   this.setState({errorMessage: "Keterangan Pending harus diisi"});
      //   return;
      // }
      
      if(this.state.permasalahan === "" || this.state.permasalahan === null || this.state.permasalahan === undefined){
        this.setState({errorMessage: "Permasalahan belum diisi"});
        return;
      }

      if(this.state.alasanUmum === "" || this.state.alasanUmum === null || this.state.alasanUmum === undefined){
        this.setState({errorMessage: "Alasan umum belum diisi"});
        return;
      }

      if(this.state.alasanRinci === "" || this.state.alasanRinci === null || this.state.alasanRinci === undefined){
        this.setState({errorMessage: "Alasan rinci belum diisi"});
        return;
      }
      
      
      this.setState({errorMessage: ""});
    }

    if(labelValue === Global.labelCancel()){
      if(this.state.keteranganCancel === "" || this.state.keteranganCancel === undefined){
        this.setState({errorMessage: "Keterangan Cancel harus diisi"});
        return;
      }

      if(this.state.permasalahan === "" || this.state.permasalahan === null || this.state.permasalahan === undefined){
        this.setState({errorMessage: "Permasalahan belum diisi"});
        return;
      }

      if(this.state.alasanUmum === "" || this.state.alasanUmum === null || this.state.alasanUmum === undefined){
        this.setState({errorMessage: "Alasan umum belum diisi"});
        return;
      }

      if(this.state.alasanRinci === "" || this.state.alasanRinci === null || this.state.alasanRinci === undefined){
        this.setState({errorMessage: "Alasan rinci belum diisi"});
        return;
      }
      
      this.setState({errorMessage: ""});
    }

    if(labelValue === Global.labelRandomLeads()){
      if(this.state.keterangan === null || this.state.keterangan === "" || this.state.keterangan === undefined){
        this.setState({errorMessage: "Keterangan harus diisi"});
        return;
      }
      
      if(this.state.permasalahan === "" || this.state.permasalahan === null || this.state.permasalahan === undefined){
        this.setState({errorMessage: "Permasalahan belum diisi"});
        return;
      }

      if(this.state.alasanUmum === "" || this.state.alasanUmum === null || this.state.alasanUmum === undefined){
        this.setState({errorMessage: "Alasan umum belum diisi"});
        return;
      }

      if(this.state.alasanRinci === "" || this.state.alasanRinci === null || this.state.alasanRinci === undefined){
        this.setState({errorMessage: "Alasan rinci belum diisi"});
        return;
      }

      this.setState({errorMessage: ""});
    }

    if(labelValue === Global.labelRegisterApps()){
      if(this.state.emailRegister === null || this.state.emailRegister === "" || this.state.emailRegister === undefined){
        this.setState({errorMessage: "Email register harus diisi"});
        return;
      }else{
        this.setState({errorMessage: ""});
      }
    }

    let params = {
      contactNumber: conversation.fromNumber,
      labelValue: labelValue,
      productCategory: this.state.cancelCategory,
      cityName: this.state.selectedCity,
      cityId: this.state.selectedCityId,
      deliveryDate: this.state.deliveryDate,
      keterangan: this.state.keterangan,
      emailRegister: this.state.emailRegister,
      keteranganPending: this.state.keteranganPending,
      keteranganCancel: this.state.keteranganCancel,
      tipeLead: this.state.tipeLead, 
      permasalahan: this.state.permasalahan,
      alasanUmum: this.state.alasanUmum,
      alasanRinci: this.state.alasanRinci
    };

    if(Global.isSalesDataPending() === true){
      params.isDataPending = true;
    }
    
    console.log("PARAMSSETLABEL", params);
    // return;
    axios
      .post(Api.setContactLabel(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("SETCONTACTLABEL : ", response.data);
        this.props.onUpdateContactLabelState(response.data.data);
        if(labelValue === Global.labelUnpaid()){
          this.setState({showLinkCreateEnquiries: true, showCancelCategory: false});
        }else{
          this.handleCloseSetLabelDialog();
        }
        // this.setState({}, () => {
          
          
        // });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  releaseSupplier = () => {

    let params = {
      supplierWaNumber: this.props.currentConversation.fromNumber,
    };
    
    axios
      .post(Api.releaseSupplier(), params, Api.getRequestHeader())
      .then((response) => {
        console.log("RELEASESUPPLIER : ", response.data);

        const responseData = response.data;

        if(responseData.success === true){
          this.setState(
            {}, 
            () => {this.props.onUpdateConversations(false);},
            this.props.onClearCurrentConversation());
        }

        
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  handleGetMessageData = () => {
    let template = Global.getMessageTemplate();
    template.message.text = "";
    template.to = this.props.currentConversation.fromNumber;
    template.contact.name = this.props.currentConversation.fromName;
    template.from = this.props.currentConversation.toNumber;
    template.receivedAt = Date.now();
    template.reffId = Date.now();
    template.messageId = template.reffId;
    template.status = "TEMPLATE";
    template.isTemplate = 1;

    return template;

  }

  handlePushMessage = (messageData) => {
    const clonedMessages = [...this.state.messages];
    clonedMessages.push(messageData);
    this.setState({messages: clonedMessages});
  }
  
  componentDidMount() {
    console.log("GLOBAL : ", Global.get());
    this.getWaMessages(false);
    this.initDataLabel();
    setTimeout(() => {
      this.getCityList();
    }, 2000);
    
    // console.log("DID MOUNT");
  }

  showInputCustomerName = () => {

  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentConversation.contactId !==
      this.props.currentConversation.contactId
    ) {
      this.getWaMessages(false);
      // console.log("DID UPDATE");
    }
  }

  renderMessages = () => {
    let i = 0;
    let messageCount = this.state.messages.length;
    let tempMessages = [];

    // const appProfile = Global.getAppProfile();
    
    while (i < messageCount) {
      let previous = this.state.messages[i - 1];
      let current = this.state.messages[i];
      let next = this.state.messages[i + 1];
      // let isMine = MY_USER_ID.includes(current.from);// current.from === MY_USER_ID;
      // let isMine =  appProfile.waNumber === current.from;// current.from === MY_USER_ID;
      let isMine =  this.props.currentConversation.fromNumber !== current.from;// current.from === MY_USER_ID;
      let currentMoment = moment(current.created_at);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
        // console.log(current.from," - ",MY_USER_ID);
      if (previous) {
        let previousMoment = moment(previous.created_at);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.from === current.from;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.created_at);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.from === current.from;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          onDownloadDocument={this.handleDownloadDocument}
          onResendMessage={this.handleResendMessage}
          onOpenCreateOrder={(messageText) => this.toggleDrawer('bottom',true, this.DRAWER_MODE_CREATE_ORDER, messageText)}
          onOpenCustomerProfile={(messageText) => this.toggleDrawer('bottom',true, this.DRAWER_MODE_CUSTOMER_PROFILE, messageText)}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleSave = (files) => {
        console.log("FILES : ", files);
        //Saving files to state for further use and closing Modal.
        this.setState({
            files: files,
            open: false,
            fileType : files[0].type.indexOf("image") !== -1 ? "IMAGE" : "DOCUMENT",
            origFileName: files[0].name
        });
    }

    handleOpenLotusDropzone = () => {
        this.setState({
            open: true,
        });
    }

    handleCloseLotusDropzone = () => {
        this.setState({
            open: false,
            files: [],
            fileType: "IMAGE"
        });
    }

    handleHapusMedia = () => {
        this.setState({
            files: [],
            fileType: "IMAGE"
        });
    }

    handleDownloadDocument = (fileName) => {
        console.log("DOWNLOADING ", fileName);
        fileName = fileName != null && fileName !== undefined && fileName.startsWith("http") ? fileName : Api.getBaseDocsUrl() + fileName;
        var win = window.open(fileName, '_blank');
        win.focus();
        // fetch(Api.getBaseDocsUrl() + fileName)
		// 	.then(response => {
        //         console.log("DOWNLOADED");
		// 		response.blob().then(blob => {
		// 			let url = window.URL.createObjectURL(blob);
		// 			let a = document.createElement('a');
		// 			a.href = url;
		// 			a.download = fileName;
		// 			a.click();
		// 		});
		// 		//window.location.href = response.url;
		// });
    }

    handlePaste = (e) => {
        
        console.log("CLIPBOARD : ", e.clipboardData.items);

        var items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (var index in items) {
            var item = items[index];
            console.log(item);
            if (item.kind === 'file') {
                console.log("AS FILE : ",item.getAsFile());
            // adds the file to your dropzone instance
            // myDropzone.addFile(item.getAsFile())
            }
        }

        var data = new DataTransfer(e.clipboardData);
        var text = data.getText();
        // var html = data.getHTML();
        var files = data.getFiles();

        // console.log("FILESCLIP : ", files);
        console.log(files, text);
        // console.log("CLIPBOARDDATA ",e.clipboardData);

        const arrayExtension = ["jpg", "jpeg", "png", "gif", "pdf"];
        

        // console.log("SPLITTED : ", splitted);

        if (files.length > 0) {

            var splitted = text == null ? ["image","png"] : text.toLowerCase().split(".");

            if(splitted[1] === "pdf"){
                // this.handleShowSnackbar(md5("wkwkwkw"));
                this.handleShowSnackbar(<>
                    <span>Maaf, saat ini fungsi paste file PDF belum dapat digunakan.</span>
                    <br />
                    <span>Silakan gunakan fungsi Drag And Drop untuk mengirim file PDF</span>
                    </>
                );
                return;
            }

            if(arrayExtension.includes(splitted[splitted.length-1])){

                this.setState({
                    files: files,
                    open: false,
                    fileType : splitted[1] === "pdf" ? "DOCUMENT" : "IMAGE",
                    origFileName: text == null ? "image.png" : text
                }, () => {
                    console.log("FILETYPE", this.state.fileType);
                });
            } else {
                // console.log("MASUK SINI");
                // alert('Hanya file bertipe ' + arrayExtension.join(", ") + " yang diijinkan");
                this.handleShowSnackbar(<>
                    <span>Hanya file bertipe </span>
                    <strong>{arrayExtension.join(", ")}</strong>
                    <span> yang diijinkan</span>
                    </>
                );
            }
        }else{
            console.log("No files found");
        }
    };

    handleShowSnackbar = (message) => {
        this.setState({snackbarVisible: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, snackbarMessage: ""});
    }

    toggleDrawer = (anchor, open, drawerMode, messageText = null) => (event) => {
      console.log("TOGGLE");
  
      // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      //   return;
      // }
  
      this.setState({[anchor]: open, drawerMode: drawerMode, orderMessageText: messageText });
    };
    
    handleShowSetLabelDialog = () => {
      let defaultDate = this.state.deliveryDate !== undefined ? moment(this.props.currentConversation.deliveryDate).format("yyyy-MM-DDThh:mm") : undefined;
      console.log("DEFAULTDATE", defaultDate);
      console.log("LOG__CURRENT_LABEL", this.props.currentConversation);
      this.setState({ showSetLabelDialog: true, defaultDeliveryDate: defaultDate });
    };
  
    handleCloseSetLabelDialog = () => {
      this.setState({ showSetLabelDialog: false, cancelCategory: "", showCancelCategory: false, errorMessage: "", selectedCity: "", selectedCityId: 0, deliveryDate: "", emailRegister: "", keteranganPending: "", keteranganCancel: "" });
    };

    handleCancelCategoryChanged = (event) => {
      this.setState({cancelCategory: event.target.value}, ()=>{
        console.log(this.state.cancelCategory);
      });
    }

    handleKeteranganPendingChanged = (event) => {
      this.setState({keteranganPending: event.target.value}, ()=>{
        console.log(this.state.keteranganPending);
      });
    }

    handleKeteranganCancelChanged = (event) => {
      this.setState({keteranganCancel: event.target.value}, ()=>{
        console.log(this.state.keteranganCancel);
      });
    }

    handleTipeLeadChange = (tipeLead) => {
      this.setState({tipeLead: tipeLead}, ()=>{
        console.log(this.state.tipeLead);
      });
    }
    
    handleToggleShowCancelCategory = (labelValue) => {
      this.initDataLabel(labelValue);
      this.setState(
        {
          showCancelCategory: !this.state.showCancelCategory
        }
      );

      if(labelValue !== Global.labelPending() && labelValue !== Global.labelUnpaid()){
        this.setState({deliveryDate: ""});
      }

    }

  initDataLabel = (labelValue) => {
    let defaultDate = this.state.deliveryDate !== undefined ? moment(this.props.currentConversation.deliveryDate).format("yyyy-MM-DDThh:mm") : undefined;
    console.log("DEFAULTDATE", defaultDate);
    this.setState(
      {
        tmpLabel: labelValue === null || labelValue === undefined || labelValue === "" ? this.props.currentConversation.label : labelValue, 
        cancelCategory: this.props.currentConversation.productCategory, 
        keteranganPending: this.props.currentConversation.keteranganPending,
        keteranganCancel: this.props.currentConversation.keteranganCancel,
        selectedCityId: this.props.currentConversation.cityId,
        selectedCity: this.props.currentConversation.cityName,
        keterangan: this.props.currentConversation.keterangan,
        emailRegister: this.props.currentConversation.emailRegister,
        deliveryDate: this.props.currentConversation.deliveryDate,
        defaultDeliveryDate: defaultDate,
        tipeLead: this.props.currentConversation.tipeLead,
        permasalahan: this.props.currentConversation.permasalahan,
        alasanUmum: this.props.currentConversation.alasanUmum,
        alasanRinci: this.props.currentConversation.alasanRinci,
      }, () => {
        console.log("INIT_STATE", this.state);
      }
    );
  }

  handleTanggalPengirimanChange = (event) => {
    console.log("DATE_ORI", event.target.value);
    var formatDeliveryDate = event.target.value.replace("/", "-");
    console.log(formatDeliveryDate);
    this.setState({ deliveryDate: formatDeliveryDate});
  }

  handleKeteranganChange = (event) => {
    this.setState({ keterangan: event.target.value});
  }

  handlePermasalahanChange = (event) => {
    this.setState({ permasalahan: event.target.value, alasanUmum: "", alasanRinci: ""});
  }

  handleAlasanUmumChange = (event) => {
    this.setState({ alasanUmum: event.target.value, alasanRinci: ""});
  }

  handleAlasanRinciChange = (event) => {
    this.setState({ alasanRinci: event.target.value});
  }

  handleEmailRegisterChange = (event) => {
    this.setState({ emailRegister: event.target.value});
  }

  handleSound = () => {
    let currentSound = this.state.muteSound;
    this.setState({muteSound: !currentSound});
  }

  handleCreateCo = () => {
    // console.log("DOWNLOADING ", contact);
    this.handleCloseCreateCoConfirmationDialog();
    let data = {
        phone: this.props.currentConversation.fromNumber
    }
    
    // const encode = Buffer.from(JSON.stringify(data)).toString('base64')
    const encode = base64.encode(JSON.stringify(data));
    console.log("dataParams : ", data, encode);

    // var url = (this.state.selectedSales.source === "LOTUS" ? Api.getLotusUrl() : Api.getLavengerUrl()) + "?fu=" + encode;
    var url = "https://lavender.prestisa.id/#!/enquiries/create/" + encode;
    //https://lavender.prestisa.id/#!/enquiries/create/eyJwaG9uZSI6IjYyODEzOTg3NDk3NTkifQ==
    var win = window.open(url, '_blank');
    win.focus();

}

handleCloseCreateCoConfirmationDialog = () => {
  this.setState({showCreateCoConfirmationDialog: false});
}

handleShowCreateCoConfirmationDialog = () => {
  this.setState({showCreateCoConfirmationDialog: true});
}

  getClassNameButtonTipeLead = (tipeLead) => {
    let className = "btn btn-block btn-sm";
    if(this.state.tipeLead === Global.tipeLeadSulitClosing() && tipeLead === this.state.tipeLead){
      return className + " btn-danger";
    }else if(this.state.tipeLead === Global.tipeLeadSedang() && tipeLead === this.state.tipeLead){
      return className + " btn-warning";
    }else if(this.state.tipeLead === Global.tipeLeadMudahClosing() && tipeLead === this.state.tipeLead){
      return className + " btn-success";
    }else{
      if(tipeLead === Global.tipeLeadSulitClosing()){
        return className + " btn btn-outline-danger";
      }else if(tipeLead === Global.tipeLeadSedang()){
        return className + " btn btn-outline-warning";
      }else if(tipeLead === Global.tipeLeadMudahClosing()){
        return className + " btn btn-outline-success";
      }
    }
  }

  render() {
    // console.log(this.state);
    // console.log("FROMNAME : ", this.props.currentConversation.fromName);
    return (
        
      // {
        this.props.currentConversation.fromNumber === "" ? (<WelcomeMessage>
          {
            this.state.listPesanBelumDibalas?.length > 0 ? <TopAlert handleConversationSelected={this.props.handleConversationSelected} generateItemConversation={this.props.generateItemConversation} listPesanBelumDibalas={this.state.listPesanBelumDibalas} /> : <></>
          }
          {
            this.state.listFuSalesOnline?.length > 0 ? <NotifyFuSalesOnline mode="banner" handleConversationSelected={this.props.handleConversationSelected} generateItemConversation={this.props.generateItemConversation} /> : <></>
          }

          </WelcomeMessage>) : 
        (
          <div className="message-list" onPaste={this.handlePaste} >
            {/* {
              this.state.listFuSalesOnline?.length > 0 ? <NotifyFuSalesOnline mode="fab" handleConversationSelected={this.props.handleConversationSelected} generateItemConversation={this.props.generateItemConversation} /> : <></>
            } */}
            {/* <Fab onClick={this.handleShowDialogPinnedMessage} size="small" color="secondary" aria-label="add" style={{position: "absolute", bottom: "1.5vh", right: "3vw"}}>
              <Tooltip title="Add a pinned note">
                <SpeakerNotes />
              </Tooltip>
            </Fab> */}
          <Toolbar
            title={this.props.currentConversation.fromName}
            leftItems={[
              <div className="badge badge-primary" style={{cursor: "pointer"}} onClick={this.handleShowCreateCoConfirmationDialog}>Create Enquiries <AddShoppingCart /></div>,
              <PinnedMessageBubble currentConversation={this.props.currentConversation} />,
              // <ExportMessages messages={this.state.messages} customerNumber={this.props.currentConversation.fromNumber} />
            ]}
            rightItems={[
              this.props.showButtonClose === true ? <Tooltip title="Close Chat"><IconButton color="error" size="small" onClick={this.props.onClose}><Close /></IconButton></Tooltip> : <></>,
              Global.getAppProfile().type === "PURCHASING-APP" && this.props.currentConversation.assignToUser > 0 ? 
              <div className="btn btn-danger" onClick={this.releaseSupplier}>
                <LockOpen />
                Selesai
              </div> : <></>,
              // <FaInfoCircle
              //   key="msglistinfo"
              //   style={{ marginRight: 10, marginLeft: 10, color: "#FFFFFF" }}
              // />,
              // <FaVideo
              //   key="msglistvideo"
              //   style={{ marginRight: 10, marginLeft: 10, color: "#FFFFFF" }}
              // />,
              // <FaPhone
              //   key="msglistphone"
              //   style={{ marginRight: 10, marginLeft: 10, color: "#FFFFFF" }}
              // />,
              this.state.isArchivedMessagesLoaded ? <></> : <div className="badge badge-warning" style={{cursor: "pointer"}} onClick={() => this.getWaMessages(true)}>Open Archived <Archive /></div>
              // Global.isSuperUser() ? <FormControlLabel control={<Switch onChange={() => this.handleSound()} checked={this.state.muteSound} />} label={this.state.muteSound === true ? <VolumeOff /> : <VolumeUp />} /> : <></>
            ]}
          >
            {
              this.state.listPesanBelumDibalas?.length > 0 ? <TopAlert handleConversationSelected={this.props.handleConversationSelected} generateItemConversation={this.props.generateItemConversation} listPesanBelumDibalas={this.state.listPesanBelumDibalas} /> : <></>
            }
          </Toolbar>
          {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              This is a success message!
            </Alert>
          </Snackbar> */}
          <div ref={this.chatContainer} className="message-list-container">
            
            {
              this.state.isLoading ? (
                <div className="container">
                  <div className="row">
                      <div className="col-5"></div>
                      <div className="col-2">
                        <ReactLoading color="#FF0000" type="bubbles" width={80} height={10} />
                      </div>
                      <div className="col-5"></div>
                  </div>
                </div>
              ) : (<></>)
            }
            
            {this.state.isLoading ? "" : this.renderMessages()}
          </div>
          {
            this.state.listPesanBelumDibalas?.length > 0 ? <TopAlert handleConversationSelected={this.props.handleConversationSelected} generateItemConversation={this.props.generateItemConversation} listPesanBelumDibalas={this.state.listPesanBelumDibalas} /> : <></>
          }
          <div className={this.getMediaContainerClasses()}>
              {
                  this.state.fileType === "IMAGE" ? (
                      // <img className="img-fluid img-thumbnail mx-auto d-block media-size" src={this.state.files.length > 0 ? this.state.files[0].path : ""} alt="noimage" />
                      <>
                          <span>Foto {this.state.files.length > 0 ? this.state.origFileName : ""}</span>
                          &nbsp;<FaTrash className="text-danger" onClick={this.handleHapusMedia} style={{cursor: "pointer"}} />
                      </>
                      
                  ) : (
                      <>
                          <span>{this.state.files.length > 0 ? this.state.origFileName : ""}</span>
                          &nbsp;<FaTrash className="text-danger" onClick={this.handleHapusMedia} style={{cursor: "pointer"}}/>
                      </>
                  )
              }
          </div>

          {/* <Fab variant="extended" className="fab">
            <VoiceOverOff /*className={classes.extendedIcon}/>
            Navigate
          </Fab> */}
          {/* {
            this.state.open === true ? (
              <LotusDropzone 
                onLotusDropzoneClose={this.handleCloseLotusDropzone} 
                onAcceptedFiles={(acceptedFiles) => this.handleSave(acceptedFiles)} 
              />) : (<></>)
          } */}

          {/* <PinnedMessageBubble /> */}
          
          <Compose
            messageValue={this.state.newMessage}
            onChange={this.handleOnChange}
            onSendMessage={this.handleSendMessage}
            onOpenDropZone={this.handleOpenLotusDropzone}
            onTemplateButtonClick={this.toggleDrawer('right',true, this.DRAWER_MODE_HSM)}
            onCsHelpButtonClick={this.handleRequestBantuanCS}
            onSetContactLabelClick = {this.handleShowSetLabelDialog}
            currentConversation={this.props.currentConversation}
            onSaveCustomerName={this.props.onSaveCustomerName}
            rightItems={[
              <FaPaperPlane key="composesend" className="text-center m-2" />,
            ]}
          />

          {/* <DropzoneDialog
              open={this.state.open}
              onSave={this.handleSave.bind(this)}
              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
              showPreviews={true}
              maxFileSize={10000000}
              onClose={this.handleClose.bind(this)}
              filesLimit={1}
          /> */}

          <Snackbar
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
              open={this.state.snackbarVisible}
              autoHideDuration={10000}
              onClose={this.handleCloseSnackbar}
              message={this.state.snackbarMessage}
          />

          {['left', 'right', 'top', 'bottom'].map((anchor) => (
              <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer 
                    anchor={anchor} 
                    open={this.state[anchor]} 
                    onClose={this.toggleDrawer(this.state.drawerMode === this.DRAWER_MODE_CREATE_ORDER || this.state.drawerMode === this.DRAWER_MODE_CUSTOMER_PROFILE ? "bottom" : 'right',false, "")}
                    
                  >
                    {
                      this.state.drawerMode === this.DRAWER_MODE_HSM ? <TemplateMessage onCloseDrawer={() => {this.setState({right: false});}} pushMessageHandler={this.handlePushMessage} messageData={this.handleGetMessageData} onClose={this.toggleDrawer('right',false)}/> : <></>
                    }

                    {
                      this.state.drawerMode === this.DRAWER_MODE_CREATE_ORDER ? <CreateOrder conversation={this.props.currentConversation} messageText={this.state.orderMessageText} waNumber={this.props.currentConversation.fromNumber} onCloseDrawer={() => {this.setState({bottom: false, orderMessageText: null});}}/> : <></>
                    }

                    {
                      this.state.drawerMode === this.DRAWER_MODE_CUSTOMER_PROFILE ? <CustomerProfile messageText={this.state.orderMessageText} waNumber={this.props.currentConversation.fromNumber} onCloseDrawer={() => {this.setState({bottom: false, orderMessageText: null});}}/> : <></>
                    }
                    
                  </Drawer>
              </React.Fragment> 
          ))}

        <Modal
          show={this.state.open}
          onHide={this.handleCloseLotusDropzone}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton><h5>Kirim File</h5></Modal.Header>
          <Modal.Body>
            <LotusDropzone 
              onLotusDropzoneClose={this.handleCloseLotusDropzone} 
              onAcceptedFiles={(acceptedFiles) => this.handleSave(acceptedFiles)} 
            />
          </Modal.Body>
          {/* <Modal.Footer>
            <span onClick={this.handleLogout} className="btn btn-danger">
              Keluar Aplikasi
            </span>
            <span
              onClick={this.handleCloseLotusDropzone}
              className="btn btn-warning"
            >
              Batalkan
            </span>
          </Modal.Footer> */}
        </Modal>

        <Modal
          show={this.state.showSetLabelDialog}
          onHide={this.handleCloseSetLabelDialog}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton><strong>Tentukan Label Pelanggan</strong></Modal.Header>
          <Modal.Body>
            <center>
              {/* Tentukan label untuk pelanggan<br /> */}
              <strong>
                {this.props.currentConversation.fromName}<br />
                {Global.maskPhoneNumber(this.props.currentConversation.fromNumber)}
              </strong><br />
              {
                this.state.showLinkCreateEnquiries ? (
                  <>
                    <br />
                    <div className="btn btn-info" onClick={() => {var win = window.open("https://lavender.prestisa.id/#!/enquiries/create", '_blank');win.focus();}}>
                      Create Enquiries
                    </div>
                  </>
                  
                ) : (<></>)
              }
            </center>
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: "#FFFFFF"}}>

            {
              !Global.isCustomerService() && !Global.isCustomerServiceSpv() ? 
              (
                <div className="row" style={{width: "100%"}}>
                  {
                    this.state.showCancelCategory ? 
                    (
                      <div className="col-8"></div>
                    ) : 
                    (<></>)
                  }
                </div>
              ) : 
              (<></>)
            }

            <div className="container">
            {
              !Global.isCustomerService() && !Global.isCustomerServiceSpv() && !this.state.showCancelCategory ? 
                (
                <>
                  <div className="row" style={{width: "100%"}}>
                    
                    {
                      [Global.labelPaid(), Global.labelUnpaid(), Global.labelPending(), Global.labelCancel(), Global.labelRandomLeads()].includes(this.props.currentConversation.contactLabel) &&  <div className="col-3">
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelPaid())} className="btn btn-success btn-block">
                          Paid
                        </span>
                      </div>
                    }

                    {
                      [Global.labelPaid(), Global.labelUnpaid(), Global.labelPending(), Global.labelCancel(), Global.labelRandomLeads()].includes(this.props.currentConversation.contactLabel) &&  <div className="col-3">
                        <span onClick={() => this.handleToggleShowCancelCategory(Global.labelDp())} className="btn btn-secondary btn-block">
                          DP
                        </span>
                      </div>
                    }

                    {
                      [Global.labelPaid(), Global.labelUnpaid(), Global.labelPending(), Global.labelCancel(), Global.labelRandomLeads()].includes(this.props.currentConversation.contactLabel) &&  <div className="col-3">
                        <span onClick={() => this.handleToggleShowCancelCategory(Global.labelUnpaid())} className="btn btn-primary btn-block">
                          Unpaid
                        </span>
                      </div>
                    }
                    
                  </div>
                  <div style={{height: "10px"}}></div>
                  <div className="row" style={{width: "100%"}}>
                    <div className="col-3">
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelPending())} className="btn btn-info btn-block">
                        Pending
                      </span>
                    </div>
                    <div className="col-3">
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelRandomLeads())} className="btn btn-dark btn-block">
                        Random Leads
                      </span>
                    </div>

                    {/* <div className="col-3">
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelRegisterApps())} style={{backgroundColor: "magenta", color: "#FFFFFF"}} className="btn btn-block">
                        Register Apps
                      </span>
                    </div> */}

                    <div className="col-3">
                      {/* <span onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelCancel())} className="btn btn-danger btn-block"> */}
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelCancel())} className="btn btn-danger btn-block">
                        Cancel
                      </span>
                    </div>

                  </div>
                </>
                ) : 
                (
                <>
                  {/* <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3 text-right">
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelCancel())} className="btn btn-danger btn-sm">
                        {
                          this.state.showCancelCategory ? "X" : "Cancel"
                        }
                      </span>
                    </div>
                  </div> */}
                </>
                )
            }
            </div>

            {
              this.state.errorMessage !== undefined && this.state.errorMessage.length > 0 ? 
              (<div className="container"><Alert severity="error">{this.state.errorMessage}</Alert></div>) : 
              (<></>)
            }

            {
              !Global.isCustomerService() && !Global.isCustomerServiceSpv() && this.state.showCancelCategory ? 
              (
                <div className="container" style={{marginTop: 0}}>
                  <div className="row">
                    <div className="col-11">
                      <strong>Label : </strong>{this.state.tmpLabel}
                    </div>
                    <div className="col-1">
                      <span onClick={() => this.handleToggleShowCancelCategory(Global.labelCancel())} className="btn btn-secondary btn-sm" style={{borderRadius: "1000px", padding: "5px"}}>
                        X
                      </span>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: "10px", marginBottom: "10px"}}>
                    <div className="col-12">
                      
                      <div className="row">
                        <div className="col-2"><strong>Tipe leads</strong> : &nbsp; </div>
                        <div className="col-2" style={{paddingLeft: 3, paddingRight: 3}}>
                          <div onClick={() => this.handleTipeLeadChange(Global.tipeLeadSulitClosing())} className={this.getClassNameButtonTipeLead(Global.tipeLeadSulitClosing())} style={{fontSize: "10px"}}>{Global.tipeLeadSulitClosing()}</div>
                        </div>
                        <div className="col-2" style={{paddingLeft: 3, paddingRight: 3}}>
                          <div onClick={() => this.handleTipeLeadChange(Global.tipeLeadSedang())} className={this.getClassNameButtonTipeLead(Global.tipeLeadSedang())} style={{fontSize: "10px"}}>{Global.tipeLeadSedang()}</div>
                        </div>
                        <div className="col-2" style={{paddingLeft: 3, paddingRight: 3}}>
                          <div onClick={() => this.handleTipeLeadChange(Global.tipeLeadMudahClosing())} className={this.getClassNameButtonTipeLead(Global.tipeLeadMudahClosing())} style={{fontSize: "10px"}}>{Global.tipeLeadMudahClosing()}</div>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                  </div>

                  {
                    this.state.tmpLabel !== Global.labelRandomLeads() && this.state.tmpLabel !== Global.labelRegisterApps() ? (
                      <>
                        <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                          <InputLabel id="demo-simple-select-outlined-label">Pilih Kategori Produk</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.cancelCategory}
                            onChange={this.handleCancelCategoryChanged}
                            label="Aplikasi"
                          >
                            {
                              Global.getListProductCategories().map(
                                (cat) => {
                                  return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                } 
                              )
                            }
                          </Select>
                        </FormControl>
                        <br /><br />
                        <div className="row">
                          <div className="col-8">
                            <Autocomplete
                                id="free-solo-2-demo"
                                // disableClearable
                                freeSolo 
                                options={this.state.listCity.map((option) => {
                                    // console.log("LABEL : ", option);
                                    return String(option.name);
                                })}
                                onChange={(event, newValue) => {
                                    // console.log("NEWVALUE : ", event);
                                    this.handleCityOnChange(newValue);
                                  }} 
                                value={this.state.selectedCity}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Pilih Kota"
                                    margin="0"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                                )}
                                size="small"
                            />
                          </div>
                          <div className="col-4">

                            {
                              this.state.tmpLabel === Global.labelPending() || this.state.tmpLabel === Global.labelUnpaid() ? 
                              (
                                <TextField
                                    id="dateStart"
                                    label="Tanggal Pengiriman"
                                    type="datetime-local"
                                    defaultValue={moment(this.props.currentConversation.deliveryDate).format("YYYY-MM-DDTHH:mm")}
                                    onChange={this.handleTanggalPengirimanChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                />
                              ) : (<></>)
                            }
                            
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-12">
                          {
                              this.state.tmpLabel === Global.labelPending() ? 
                              (
                                <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Keterangan Pending</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.keteranganPending}
                                    onChange={this.handleKeteranganPendingChanged}
                                    label="Aplikasi"
                                  >
                                    {
                                      Global.getListKeteranganPending().map(
                                        (cat) => {
                                          return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                        } 
                                      )
                                    }
                                  </Select>
                                </FormControl>
                              ) : (<></>)
                            }
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12">
                          {
                              this.state.tmpLabel === Global.labelCancel() ? 
                              (
                                <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                                  <InputLabel id="demo-simple-select-outlined-label">Pilih Keterangan Cancel</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.keteranganCancel}
                                    onChange={this.handleKeteranganCancelChanged}
                                    label="Aplikasi"
                                  >
                                    {
                                      Global.getListKeteranganCancel().map(
                                        (cat) => {
                                          return (<MenuItem key={cat} value={cat}>{cat}</MenuItem>);
                                        } 
                                      )
                                    }
                                  </Select>
                                </FormControl>
                              ) : (<></>)
                            }
                          </div>
                        </div>
                      </>
                    ) : (<></>)
                  }
                  
                  {
                    this.state.tmpLabel === Global.labelRegisterApps() ? 
                    (
                      <div>
                        <br />
                        <TextField
                          id="txtRegisterApp"
                          label="Email Register"
                          // type="datetime-local"
                          value={this.state.emailRegister}
                          variant="outlined"
                          defaultValue={this.state.emailRegister}
                          onChange={this.handleEmailRegisterChange}
                          // className={classes.textField}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          multiline
                          fullWidth
                          size="small"
                        />
                      </div>
                    ) : (<></>)
                  }
                  {
                    this.state.tmpLabel === Global.labelRandomLeads() ? 
                    (
                      <div>
                        <br />
                        <TextField
                          id="txtKeterangan"
                          label="Keterangan"
                          // type="datetime-local"
                          value={this.state.keterangan}
                          variant="outlined"
                          defaultValue={this.state.keterangan}
                          onChange={this.handleKeteranganChange}
                          // className={classes.textField}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          multiline
                          fullWidth
                          size="small"
                        />
                      </div>
                    ) : (<></>)
                  }

                  {
                    [Global.labelCancel(), Global.labelRandomLeads(), Global.labelPending()].includes(this.state.tmpLabel) ? (
                      <div className="row">
                        <div className="col-12">
                          <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                            <InputLabel id="demo-simple-select-outlined-label">Permasalahan</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={this.state.permasalahan}
                              onChange={this.handlePermasalahanChange}
                              label="Aplikasi"
                            >
                              {
                                Global.getListPermasalahan().map(
                                  (permasalahan, index) => {
                                    return (<MenuItem key={"permasalahan"+index} value={permasalahan.permasalahan}>{permasalahan.permasalahan}</MenuItem>);
                                  } 
                                )
                              }
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-12">
                          <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                            <InputLabel id="demo-simple-select-outlined-label">Alasan Umum</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={this.state.alasanUmum}
                              onChange={this.handleAlasanUmumChange}
                              label="Aplikasi"
                            >
                              {
                                Global.getListAlasanUmum(this.state.permasalahan).map(
                                  (alasanUmum, index) => {
                                    return (<MenuItem key={"alasanUmum"+index} value={alasanUmum.name}>{alasanUmum.name}</MenuItem>);
                                  } 
                                )
                              }
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-12">
                          <FormControl size="small" variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                            <InputLabel id="demo-simple-select-outlined-label">Alasan Rinci</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={this.state.alasanRinci}
                              onChange={this.handleAlasanRinciChange}
                              label="Aplikasi"
                            >
                              {
                                Global.getListAlasanRinci(this.state.permasalahan, this.state.alasanUmum).map(
                                  (alasanRinci, index) => {
                                    return (<MenuItem key={"alasanRinci"+index} value={alasanRinci}>{alasanRinci}</MenuItem>);
                                  } 
                                )
                              }
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    ) : (<></>)
                  }

                    <br />
                    <div onClick={() => this.handleSetContactLabel(this.props.currentConversation, this.state.tmpLabel)} className="btn btn-block btn-primary">Simpan</div>
                </div>
              ) : 
              (<></>)
            }

            {/* {
              !Global.isCustomerService() && !Global.isCustomerServiceSpv() && this.state.showCancelCategory ? 
              (
                <div className="container">
                  <FormControl variant="outlined" style={{width: '100%', marginTop: "15px"}}>
                      <InputLabel id="demo-simple-select-outlined-label">Pilih Kota</InputLabel>
                      <Autocomplete
                        id="free-solo-2-demo"
                        // disableClearable
                        freeSolo 
                        options={this.state.listKota.map((option) => {
                            // console.log("LABEL : ", option);
                            return String(option.name);
                        })}
                        onChange={(event, newValue) => {
                            // console.log("NEWVALUE : ", event);
                            // this.handleSelectionChanged(newValue);
                          }} 
                        value={this.state.selectedKota}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Pilih Kota"
                            margin="0"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                        )}
                    />
                    </FormControl>
                    <br /><br />
                    <div onClick={() => this.handleSetContactLabel(this.props.currentConversation, this.state.tmpLabel)} className="btn btn-block btn-light">Simpan Label {this.state.tmpLabel}</div>
                </div>
              ) : 
              (<></>)
            } */}
            
            {
              Global.get().admin || Global.isCustomerService() || Global.isCustomerServiceSpv() ? 
              (
                <>
                  {/* <div className="row"> */}
                    <div className="">
                      <center><strong>Label khusus CS</strong></center>
                    </div>
                  {/* </div> */}
                  <div className="row"  style={{width: "100%"}}>
                    <div className="col-6">
                      <span style={{backgroundColor: "#e57373"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelComplaintKeterlambatan())} className="btn btn-info btn-block">
                        {Global.labelComplaintKeterlambatan()}
                      </span>
                    </div>
                    <div className="col-6">
                      <span style={{backgroundColor: "#f06292"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelComplaintProdukTidakSesuai())} className="btn btn-dark btn-block">
                        {Global.labelComplaintProdukTidakSesuai()}
                      </span>
                    </div>
                  </div>
                  {/* <br /> */}
                  <div className="row" style={{width: "100%"}}>
                    <div className="col-6">
                      <span style={{backgroundColor: "#ba68c8"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelComplaintLayuRusak())} className="btn btn-info btn-block">
                        {Global.labelComplaintLayuRusak()}
                      </span>
                    </div>
                    <div className="col-6">
                      <span style={{backgroundColor: "#7986cb"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelComplaintReport())} className="btn btn-dark btn-block">
                        {Global.labelComplaintReport()}
                      </span>
                    </div>
                  </div>
                  {/* <br /> */}
                  <div className="row" style={{width: "100%"}}>
                    <div className="col-6">
                      <span style={{backgroundColor: "#64b5f6"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelKonfirmasiPesanan())} className="btn btn-info btn-block">
                        {Global.labelKonfirmasiPesanan()}
                      </span>
                    </div>
                    <div className="col-6">
                      <span style={{backgroundColor: "#4db6ac"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelLainLain())} className="btn btn-info btn-block">
                        {Global.labelLainLain()}
                      </span>
                    </div>
                  </div>
                  {/* <br /> */}
                  <div className="row" style={{width: "100%"}}>
                    <div className="col-6">
                      <span style={{backgroundColor: "#a1887f"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelComplaintPesananTidakDibuatkan())} className="btn btn-dark btn-block">
                        {Global.labelComplaintPesananTidakDibuatkan()}
                      </span>
                    </div>
                    <div className="col-6">
                      <span style={{backgroundColor: "#ff8a65"}} onClick={() => this.handleSetContactLabel(this.props.currentConversation, Global.labelReport())} className="btn btn-dark btn-block">
                        {Global.labelReport()}
                      </span>
                    </div>
                  </div>
                </>
              ) : 
              (<></>)
            }

            
            
            {/* <span
              onClick={this.handleCloseSetLabelDialog}
              className="btn btn-warning"
            >
              Batalkan
            </span> */}
          </Modal.Footer>
        </Modal>

          <Modal
            show={this.state.showCreateCoConfirmationDialog}
            onHide={this.handleCloseCreateCoConfirmationDialog}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
              <strong>Konfirmasi Enquiries</strong>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4 text-right" style={{fontSize: "13px"}}>
                        <strong>Nama :</strong>
                    </div>
                    <div className="col-8 text-left" style={{fontSize: "13px"}}>
                        {this.props.currentConversation.fromName}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 text-right" style={{fontSize: "13px"}}>
                        <strong>Nomor WhatsApp :</strong>
                    </div>
                    <div className="col-8 text-left" style={{fontSize: "13px"}}>
                        {Global.maskPhoneNumber(this.props.currentConversation.fromNumber)}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span onClick={this.handleCreateCo} className="btn btn-primary">
                  Buat Enquiries Sekarang
                </span>
                <span
                onClick={this.handleCloseCreateCoConfirmationDialog}
                className="btn btn-warning"
                >
                Batal
                </span>
            </Modal.Footer>
          </Modal>

        </div>
        )
      // }

      
    );
  }

  getMediaContainerClasses = () => {
      const classes = "container bg-light";
      return this.state.files.length > 0 ? classes : classes + " d-none";
  }

}

export default MessageList;
